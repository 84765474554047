import React, { useState } from 'react';
import './styles.css'; // Asegúrate de crear un archivo CSS para los estilos 
import { useMediaQuery } from 'react-responsive'
import Logo from '../../assets/phone/15/4-black.png'


const Navbar = ({page}) => {
  const [isResponsive, setIsResponsive] = useState(false);

  const pc = useMediaQuery({ query: '(min-width: 1200px)' })

  const toggleNavbar = () => {
    setIsResponsive(!isResponsive);
  };

  return (
    <div className={`navbar ${isResponsive ? 'responsive' : ''}`} id="myNavbar">
      {pc ? <div className="icon-left"><img alt="logo" className="img-close" src={Logo}/></div> : null}
      <div className="menu">
        {isResponsive &&
          <div className='icon-responsive'>
            <img alt="logo" className="img-close" src={Logo}/>
          </div>
        }
        <a href="#" onClick={(e) => {
          e.preventDefault()
          page('home')
          setIsResponsive(false)
        }}>Inicio</a>
        <a href="#" onClick={(e) => {
          e.preventDefault()
          page('city')
          setIsResponsive(false)
        }}>Ciudades</a>
        <a href="#" onClick={(e) => {
          e.preventDefault()
          page('appliances')
          setIsResponsive(false)
        }}>Electrodomésticos</a>
        <a href="#" onClick={(e) => {
          e.preventDefault()
          page('brands')
          setIsResponsive(false)
        }}>Marcas</a>
        <a href="#" onClick={(e) => {
          e.preventDefault()
          page('about')
          setIsResponsive(false)
        }}>Nosotros</a>
      </div>
      {!isResponsive ?
      <a href="javascript:void(0);" className="icon" onClick={toggleNavbar}>
        &#9776;
      </a>:
      <a href="javascript:void(0);" className="icon-close" onClick={toggleNavbar}>
        &#9747;
      </a>}
    </div>
  );
};

export default Navbar;
