import React from 'react';
import styles from "./styles.module.css";
import './styles.scss'
import FaqImg from '../../assets/faq.png'
import chevronDown from "../../chevron-down.svg";
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';

export default function Faq() {
  const AccordionItem = ({ header, ...rest }) => (
    <Item
      {...rest}
      header={
        <>
          {header}
          <img className={styles.chevron} src={chevronDown} alt="Chevron Down" />
        </>
      }
      className={styles.item}
      buttonProps={{
        className: ({ isEnter }) =>
          `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`,
      }}
      contentProps={{ className: styles.itemContent }}
      panelProps={{ className: styles.itemPanel }}
    />
  );

  return (
    <div className='faq'>
      <div className='img'>
        <img src={FaqImg} alt="faq"/>
      </div>
      <div className='title'>
        <h1>¿Por qué elegir a Novaserv como tu proveedor de servicio técnico?</h1>
        <p>
          En <b>Novaserv</b>, no solo reparamos electrodomésticos, restauramos la tranquilidad y el confort en tu hogar. Sabemos lo importante que es para ti que todo funcione a la perfección, desde la lavadora que cuida tu ropa hasta la nevera que conserva tus alimentos frescos.
        </p>
      </div>
      <div className='faq-list'>
        <Accordion transition transitionTimeout={250}>
          <AccordionItem header="🌟 Compromiso con la calidad:">
            Cada intervención es una promesa de excelencia. Nuestros técnicos altamente capacitados trabajan con precisión y dedicación para garantizar que cada reparación sea duradera y eficiente.
          </AccordionItem>
          <AccordionItem header="⚙️ Soluciones rápidas y efectivas:">
            Entendemos que tu tiempo es valioso. Por eso, ofrecemos soluciones rápidas sin comprometer la calidad, asegurándonos de que vuelvas a tu rutina lo antes posible.
          </AccordionItem>
          <AccordionItem header="💡 Confianza y seguridad:">
            Tus electrodomésticos están en manos de expertos. Trabajamos con las mejores herramientas y técnicas para brindarte un servicio seguro y confiable, respetando siempre tu espacio y tus pertenencias.
          </AccordionItem>
          <AccordionItem header="🌱 Compromiso con la sostenibilidad:">
            No solo cuidamos de tus electrodomésticos, también cuidamos del planeta. Promovemos prácticas sostenibles y responsables, asegurando que nuestras reparaciones y mantenimientos sean amigables con el medio ambiente.
          </AccordionItem>
          <AccordionItem header="🤝 Atención personalizada:">
            Cada cliente es único para nosotros. Escuchamos tus necesidades y te ofrecemos soluciones a medida, porque tu satisfacción es nuestra máxima prioridad.
          </AccordionItem>
        </Accordion>
      </div>
      <div>
        <p>
          Elegir <b>Novaserv</b> es elegir tranquilidad, calidad y un servicio excepcional. Permítenos ser tu aliado en el mantenimiento y cuidado de tus electrodomésticos. 📞🔧 Contacta con nosotros hoy mismo y descubre por qué somos la opción preferida de tantos hogares.
        </p>
      </div>
    </div>
	)
}
