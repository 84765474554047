import './styles.scss'
import Nova from '../../assets/phone/15/4.png'
import { MdOutlineWhatsapp, MdMailOutline } from "react-icons/md";
import { FaInstagram, FaFacebookF  } from "react-icons/fa";



export default function Footer(){
    return(
        <div className='footer'>
            <div className='img-footer'>
                <img src={Nova} alt="Novaserv"/>
            </div>
            <div className='content'>
                <div>
                    <h3>CONTACTO</h3>
                    <div><a href="http://wa.link/4ub9p2" target='_blank'><MdOutlineWhatsapp size={25} />&nbsp;(+57) 321 574 03 53</a></div>
                    <div><MdMailOutline size={25}/>&nbsp;centrodeservicionovaserv@gmail.com</div>
                </div>
                <div>
                    <h3>HORARIOS</h3>
                    <div>Lunes a Viernes: 7:00 AM - 6:00 PM</div>
                    <div>Sábados: 8:00 AM - 6:00 PM</div>
                    <div>Domingos y Festivos: Con cita previa</div>
                </div>
                <div>
                    <h3>MÉTODOS DE PAGO</h3>
                    <div>Nequi</div>
                    <div>Daviplata</div>
                    <div>Efectivo</div>
                </div>
                <div>
                    <h3>REDES SOCIALES</h3>
                    <div><FaInstagram size={25}/>&nbsp;@centrodeservicionovaserv</div>
                    <div><FaFacebookF size={25}/>&nbsp;@novaservst</div>
                </div>
            </div>
        </div>
    )
}