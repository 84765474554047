import { useState, useEffect } from 'react'
import './App.scss';
import './Pages.scss';
import { useMediaQuery } from 'react-responsive'
import Navbar from './components/navbar';
import Content from './components/content';
import Faq from './components/faq';
import Footer from './components/footer';
// Images for PC
import Page1boton from './assets/pc/1/boton.png'
import Page1png2 from './assets/pc/1/2.png'
import Page2png2 from './assets/pc/2/2.png'
import Page3png2 from './assets/pc/3/2.png'
import Page4png2 from './assets/pc/4/2.png'
import Page5png2 from './assets/pc/5/2.png'
import Page5png3 from './assets/pc/5/3.png'
import Page5png4 from './assets/pc/5/4.png'
import Page5png5 from './assets/pc/5/5.png'
import Page5png6 from './assets/pc/5/6.png'
import Page5png7 from './assets/pc/5/7.png'
import Page5png8 from './assets/pc/5/8.png'
import Page5png9 from './assets/pc/5/9.png'
import Page5png10 from './assets/pc/5/10.png'
import Page5png11 from './assets/pc/5/11.png'
import Page5png12 from './assets/pc/5/12.png'
import Page5png13 from './assets/pc/5/13.png'
import Page6png2 from './assets/pc/6/2.png'
import Page7png2 from './assets/pc/7/2.png'
import Page8png2 from './assets/pc/8/2.png'
import Page9png2 from './assets/pc/9/2.png'
import Page10png2 from './assets/pc/10/2.png'
import Page11png2 from './assets/pc/11/1.png'
import Page12png2 from './assets/pc/12/2.png'
import Page13png2 from './assets/pc/13/2.png'
import Page14png2 from './assets/pc/14/2.png'
import Page15png2 from './assets/pc/15/2.png'
import Page15png10 from './assets/pc/15/10.png'
import Page15png11 from './assets/pc/15/11.png'
import Page15png12 from './assets/pc/15/12.png'
import Page15png13 from './assets/pc/15/13.png'
// Images for Mobile
import Page1x7 from './assets/phone/1/7.png'
import Page2x3 from './assets/phone/2/3.png'
import Page3x3 from './assets/phone/3/3.png'
import Page4x3 from './assets/phone/4/3.png'
import Page5x14 from './assets/phone/5/14.png'
import Page6x3 from './assets/phone/6/3.png'
import Page7x3 from './assets/phone/7/3.png'
import Page8x3 from './assets/phone/8/3.png'
import Page9x3 from './assets/phone/9/3.png'
import Page10x3 from './assets/phone/10/3.png'
import Page11x3 from './assets/phone/11/3.png'
import Page13x3 from './assets/phone/13/3.png'
import Page14x3 from './assets/phone/14/3.png'
import Page15x3 from './assets/phone/15/3.png'
import Page15x4 from './assets/phone/15/4.png'

import City1 from './assets/city.png'
import Elect1 from './assets/electrodomesticos2.png'

function App() {
  const pc = useMediaQuery({ query: '(min-width: 1366px)' })
  const mobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const clickListener = (e) => {
    if (e.target.id === 'whatsapp') {
      window.open('http://wa.link/4ub9p2', '_blank')
    }
  };
  useEffect(() => {
    document.addEventListener(`click`, clickListener);
    return () => {
      document.removeEventListener(`click`, clickListener);
    };
  }, []);

  const dataPage = [
    {
      name: "page1",
      content: `
          <div class='page'>
            <div class='divuno'>
              <h1>ESPECIALISTAS EN HACER TU VIDA MÁS FÁCIL</h1>
              <img alt="page1" src='${Page1x7}'/>
            </div>
            <div class='divdos'>
              <img alt='page1' src='${Page1png2}'/>
            </div>
            <div class='divtres'>
              <div>
                <h3>Servicio Linea Blanca Hogar</h3>
                <p>¡Ahora puede arreglar la nevera, horno, refrigerador, lavadora y dejarlos como nuevos en una sola visita!</p>
                <p>Contamos con un servicio de prevención temprana</p>
                <p>Ofrecemos <span>90 días</span> de garantía en todos los servicios</p>
                <img alt='page1' class='botonpng' id='whatsapp' src='${Page1boton}'/>
              </div>
            </div>
          </div>
        `
    },
    {
      name: "page2",
      content: `
          <div class='page'>
            <div class='divuno'>
              <div>
                <h3>De visita</h3>
                <p>Estas por recibir la visita de tus amigos y preciso se daña la nevera.<br/><span>Ok, se daño, pero la cerveza, la gaseosa caliente no le gustan a nadie.</span></p>
                <p>Te escribe tu esposo y te dice que tu suegra va en camino.<br/><span>Y recuerdas que la lavadora lleva dañada 2 días.<br/>Ya conoces a tu suegra.</span></p>
                <p>Ahh y el calentador no funciona.<br/><span>Te acuerdas que ya no te gustan las duchas de agua fría.</span></p>
                <p>¡Se le ocurre arreglar el horno y lo termina dañando mas!<br/><span>No dejes que tu esposo le meta mano a los electrodomésticos.</span></p>
                <img alt='page2' class='botonpng' id='whatsapp' src='${Page1boton}'/>
              </div>
            </div>
            <div class='divdos'>
              <img alt='page2' src='${pc ? Page2png2 : Page2x3}'/>
            </div>
            <div class='divtres'>
              
            </div>
          </div>
        `
    },
    {
      name: "page3",
      content: `
          <div class='page'>
            <div class='divuno'>
              
            </div>
            <div class='divdos'>
              <img alt='page2' src='${pc ? Page3png2 : Page3x3}'/>
            </div>
            <div class='divtres'>
              <div>
                <p>Te entendemos, esos son casos que vivimos a diario. Por eso la recomendación que les hacemos a nuestros clientes es:</p>
                <span>No lo haga usted porque le puede salir mas caro.</span>
                <p>Tu esposo tiene buenas intenciones, pero no tiene ni la herramienta y tampoco el tiempo para hacerlo.</p>
                <img alt='page2' class='botonpng' id='whatsapp' src='${Page1boton}'/>
              </div>
            </div>
          </div>
        `
    },
    {
      name: "page4",
      content: `
          <div class='page'>
            <div class='divuno'>
              <div>
                <h3>Con NovaServ ahorre hasta un 20%</h3>
                <p>Ya esta demostrado que se puede ahorrar hasta un 20% en la factura de la energía por hacer mantenimiento a sus electrodomésticos.</p>
                <p>Que la lavadora lave.<br/>Que el horno caliente.<br/>Que la nevera enfrié y vuelva a ser como nueva.</p>
                <p>Esas son nuestras preocupaciones.</p>
                <img alt='page2' class='botonpng' id='whatsapp' src='${Page1boton}'/>
              </div>
            </div>
            <div class='divdos'>
              <img alt='page2' src='${pc ? Page4png2 : Page4x3}'/>
            </div>
            <div class='divtres'>
              
            </div>
          </div>
        `
    },
    {
      name: "page5",
      content: `
          <div class='page'>
            <div class='divuno'>
              <div>
                <img alt='page2' src='${Page5png3}'/>
                <img alt='page2' src='${Page5png4}'/>
                <img alt='page2' src='${Page5png5}'/>
                <img alt='page2' src='${Page5png6}'/>
                <img alt='page2' src='${Page5png7}'/>
                <img alt='Page2' src='${Page5png8}'/>
                <img alt='page2' src='${Page5png9}'/>
                <img alt='page2' src='${Page5png10}'/>
                <img alt='page2' src='${Page5png11}'/>
                <img alt='page2' src='${Page5png12}'/>
                <img alt='page2' src='${Page5png13}'/>
              </div>
            </div>
            <div class='divdos'>
              <div>
                <h3>En NovaServ</h3>
                <p>Ofrecemos servicios de mantenimiento y reparación de neveras y lavadoras, hornos, secadoras es decir:</p>
                <span>Toda la línea blanca.</span>
              </div>
              <img alt='page2' src='${pc ? Page5png2 : Page5x14}'/>
            </div>
          </div>
        `
    },
    {
      name: "page6",
      content: `
          <div class='page'>
            <div class='divuno'>
              <div>
                <p>Nuestros técnicos están capacitados para brindarle un trabajo de calidad, podemos resolver cualquier problema que tenga con sus electrodomésticos</p>
              </div>
            </div>
            <div class='divdos'>
              <img alt='page2' src='${pc ? Page6png2 : Page6x3}'/>
            </div>
            <div class='divtres'>
              
            </div>
          </div>
        `
    },
    {
      name: "page7",
      content: `
          <div class='page'>
            <div class='divuno'>
              
            </div>
            <div class='divdos'>
              <img alt='page2' src='${pc ? Page7png2 : Page7x3}'/>
            </div>
            <div class='divtres'>
              <div>
                <h3>Nunca tendrá que lidiar con un producto defectuoso, con fallas o en mal estado</h3>
                <p>Con nuestro Centro de Servicio y Reparación Calificado, podrá disfrutar de sus electrodomésticos sin preocupaciones.</p>
                <span>Ofrecemos 90 días de garantía en todos los servicios.</span>
              </div>
            </div>
          </div>
        `
    },
    {
      name: "page8",
      content: `
          <div class='page'>
            <div class='divuno'>
              <div>
                <h3>¿No está seguro del estado de sus electrodomésticos?</h3>
                <p>
                Así podrá:
                  <ul>
                    <li>Ahorrar tiempo</li>
                    <li>Ahorrar dinero</li>
                    <li>Ahorrar preocupaciones incensarías</li>
                    <li>Evitar dolores de cabeza</li>
                    <li>Evitar peleas con su pareja</li>
                  </ul>
                </p>
                <img alt='page2' class='botonpng' id='whatsapp' src='${Page1boton}'/>
              </div>
            </div>
            <div class='divdos'>
              <img alt='page2' src='${pc ? Page8png2 : Page8x3}'/>
            </div>
            <div class='divtres'>
              
            </div>
          </div>
        `
    },
    {
      name: "page9",
      content: `
          <div class='page'>
            <div class='divuno'>
              
            </div>
            <div class='divdos'>
              <img alt='page2' src='${pc ? Page9png2 : Page9x3}'/>
            </div>
            <div class='divtres'>
              <div>
                <h3>Solución completa</h3>
                <p>Contamos con técnicos expertos seleccionados y capacitados a cargo de su servicio.</p>
                <p>Tendrá un contacto en menos de 24 horas para programar el servicio y hacer las recomendaciones necesaria para realizar el trabajo completo en el tiempo justo.</p>
                <span>Trabajo 100% garantizado.</span>
              </div>
            </div>
          </div>
        `
    },
    {
      name: "page10",
      content: `
          <div class='page'>
            <div class='divuno'>
              <div>
                <h3>¿Cómo quitar las preocupaciones?</h3>
                <p>
                Con NovaServ no volverá a decir esto
                  <ul>
                    <li>Que nadie me visite</li>
                    <li>No invitar amigos a casa</li>
                    <li>Que no llegue ningún familiar</li>
                  </ul>
                </p>
                <p>Antes de tener esos pensamientos, mejor disfruta de la compañía de tus mejores amigos y familiares en casa.<br/>Que de los electrodomésticos nos encargamos nosotros.</p>
              </div>
            </div>
            <div class='divdos'>
              <img alt='page2' src='${pc ? Page10png2 : Page10x3}'/>
            </div>
            <div class='divtres'>
              
            </div>
          </div>
        `
    },
    {
      name: "page11",
      content: `
          <div class='page'>
            <div class='divuno'>
              
            </div>
            <div class='divdos'>
              <img alt='page2' src='${pc ? Page11png2 : Page11x3}'/>
            </div>
            <div class='divtres'>
              <div>
                <h3>Cuanto le esta costando</h3>
                <p>A veces solo 1 llamada arregla el problema.<br/>NovaServ especialistas en hacer tu vida más fácil.</p>
                <span>Mi esposo lo arregla.</span>
                <p>Típica frase de la mayoría de los hogares que nos terminan llamando a nosotros.<br/>Esta frase te puede costar mas de la cuenta, deja que tu esposo sea tu esposo, no quien “Arregle los electrodomésticos”</p>
                <img alt='page2' class='botonpng' id='whatsapp' src='${Page1boton}'/>
              </div>
            </div>
          </div>
        `
    },
    {
      name: "page12",
      content: `
          <div class='page'>
            <div class='divuno'>
              <div>
                <h3>Tus amigas te reconocen</h3>
                <p>Cuando preparas esa receta que a todos les encanta.</p>
                <p>Pero, ¿Que pasaría si se daña el horno, preciso en ese momento?</p>
                <p>NovaServ es también prevención.</p>
              </div>
            </div>
            <div class='divdos'>
              <img alt='page2' src='${Page12png2}'/>
            </div>
            <div class='divtres'>
              
            </div>
          </div>
        `
    },
    {
      name: "page13",
      content: `
          <div class='page'>
            <div class='divuno'>
              
            </div>
            <div class='divdos'>
              <img alt='page2' src='${pc ? Page13png2 : Page13x3}'/>
            </div>
            <div class='divtres'>
              <div>
                <h3>Pan comido!!!</h3>
                <p>Ahora, cualquier trabajo de mantenimiento, reparación, instalación es pan comido con NovaServ.</p>
                <p>Es una pena que no pueda recibir a sus amigos porque la nevera se daño, pero no se preocupe, NovaServ lo arregla fácilmente.</p>
              </div>
            </div>
          </div>
        `
    },
    {
      name: "page14",
      content: `
          <div class='page'>
            <div class='divuno'>
              <div>
                <h3>Nuevo descubrimiento</h3>
                <p>Que acaba con los problemas de los electrodomésticos.</p>
                <p>Bueno, digamos que mas que un descubrimiento es algo que ponemos en practica en NovaServ y es hacer mantención preventiva a los electrodomésticos.</p>
                <p>Estas mantenciones se hacen cada 6 a 9 meses y vemos el estado actual de los electrodomésticos, los revisamos y los dejamos como nuevos, el 99,9% de las veces quedan optimizados y ahorrando energía.</p>
                <img alt='page2' class='botonpng' id='whatsapp' src='${Page1boton}'/>
              </div>
            </div>
            <div class='divdos'>
              <img alt='page2' src='${pc ? Page14png2 : Page14x3}'/>
            </div>
            <div class='divtres'>
              
            </div>
          </div>
        `
    },
    {
      name: "page15",
      content: `
          <div class='page'>
            <div class='divuno'>
              
            </div>
            <div class='divdos'>
              <img alt='page2' src='${pc ? Page15png2 : Page15x4}'/>
            </div>
            <div class='divtres'>
              <div>
                <h3>El fin de semana es para descansar</h3>
                <p>No para estar “arreglando electrodomésticos”</p>
                <p>Tu descansas, nosotros trabajamos.</p>
                <p><span>NovaServ Especialistas en hacer tu vida más fácil.</span></p>
                <img alt='page2' class='botonpng' id='whatsapp' src='${Page1boton}'/>
              </div>
            </div>
            <div class='divcuatro'>
              <div>
                <img alt='page2' class='botonpng' id='whatsapp' src='${pc ? Page15png10 : Page15x3}'/>
                <img alt='page2' class='botonpng' id='whatsapp' src='${pc ? Page15png11 : ''}'/>
                <img alt='page2' class='botonpng' id='whatsapp' src='${pc ? Page15png12 : ''}'/>
                <img alt='page2' class='botonpng' id='whatsapp' src='${pc ? Page15png13 : ''}'/>
              </div>
              <div class="link">${pc ? 'www.novaserv.com' : null}</div>
            </div>
          </div>
        `
    }
  ]

  const city = [
    {
      name: "city1",
      content: `
          <div class='city'>
            <div class='divuno'>
              <div class="div1">
                <h1>CIUDADES</h1>
              </div>
            </div>
            <div class='divdos'>
              <div class="divdosuno">
                <div>
                  <p>
                    Prestamos atención de servicio técnico para las siguientes ciudades:
                  </p>
                </div>
                <div class="options">
                  <div>
                    <ul>
                      <li>Bogotá</li>
                      <li>Chía</li>
                      <li>Cota</li>
                      <li>Funza</li>
                      <li>Mosquera</li>
                      <li>Madrid</li>
                    </ul>
                  </div>
                  <div>
                    <ul>
                      <li>Sibaté</li>
                      <li>Soacha</li>
                      <li>Tabio</li>
                      <li>Tenjo</li>
                      <li>Tocancipá</li>
                      <li>Zipaquirá</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="divdosdos">
                <img alt='page1' src='${City1}'/>
              </div>
            </div>
            <div class="divtres">
              <img alt='page1' id='whatsapp' src='${Page1boton}'/>
            </div>
          </div>
        `
    },
    {
      name: "city2",
      content: `
        <div class="city">
          <p>Asesoría, instalación, mantenimiento y reparación de electrodomésticos a domicilio en Bogotá y ciudades aledañas.</p> 

          <p>Electrodomésticos mayores:<br/>Neveras, Lavadoras, Calentadores, Aires acondicionados, Nevecones, Estufas y campanas extractoras en Bogotá.</p> 

          <p>Contamos con servicio técnico en marcas como Samsung, LG, Haceb, Whirlpool, Centrales, Electrolux, Mabe, General Electric, entre otras.</p>

          <p>NOVASERV tiene personal  altamente capacitado para brindar un servicio de calidad, 100% garantizado y con repuestos originales para mantenimiento y reparación de electrodomésticos.</p>

          <p>Realizamos mantenimiento preventivo y soporte correctivo de las principales marcas de electrodomésticos usadas en Colombia con personal técnico idóneo para atender profesionalmente sugerencias y servicio técnico especializado para atender las necesidades de nuestros clientes.</p>

          <img alt='page1' id='whatsapp' src='${Page1boton}'/>

        </div>
      `
    }
  ]

  const appliances = [
    {
      name: "elect1",
      content: `
          <div class='city'>
            <div class='divuno'>
              <div class="div1">
                <h1>ELECTRODOMESTICOS</h1>
              </div>
            </div>
            <div class='divdos'>
              <div class="divdosdos">
                <img alt='page1' src='${Elect1}'/>
              </div>
              <div class="divdosuno">
                <div>
                  <p>
                    Damos soporte a tus “manos derechas” en el hogar
                  </p>
                </div>
                <div class="options">
                  <div>
                    <ul>
                      <li>Aires acondicionados</li>
                      <li>Calentadores de agua</li>
                      <li>Campanas extractoras</li>
                      <li>Estufas y Cubiertas</li>
                      <li>Lavadoras</li>
                      <li>Neveras y Nevecones</li>
                      <li>Refrigeradores</li>
                      <li>Secadoras</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="divtres">
              <img alt='page1' id='whatsapp' src='${Page1boton}'/>
            </div>
          </div>
        `
    },
    {
      name: "city2",
      content: `
        <div class="city">
          <p>Asesoría, instalación, mantenimiento y reparación de electrodomésticos a domicilio en Bogotá y ciudades aledañas.</p> 

          <p>Electrodomésticos mayores:<br/>Neveras, Lavadoras, Calentadores, Aires acondicionados, Nevecones, Estufas y campanas extractoras en Bogotá.</p> 

          <p>Contamos con servicio técnico en marcas como Samsung, LG, Haceb, Whirlpool, Centrales, Electrolux, Mabe, General Electric, entre otras.</p>

          <p>NOVASERV tiene personal  altamente capacitado para brindar un servicio de calidad, 100% garantizado y con repuestos originales para mantenimiento y reparación de electrodomésticos.</p>

          <p>Realizamos mantenimiento preventivo y soporte correctivo de las principales marcas de electrodomésticos usadas en Colombia con personal técnico idóneo para atender profesionalmente sugerencias y servicio técnico especializado para atender las necesidades de nuestros clientes.</p>

          <img alt='page1' id='whatsapp' src='${Page1boton}'/>

        </div>
      `
    }
  ]

  const brands = [
    {
      name: "page5",
      content: `
          <div class='page'>
            <h1>MARCAS</h1>
            <div class='divuno'>
              <div>
                <img alt='page2' src='${Page5png3}'/>
                <img alt='page2' src='${Page5png4}'/>
                <img alt='page2' src='${Page5png5}'/>
                <img alt='page2' src='${Page5png6}'/>
                <img alt='page2' src='${Page5png7}'/>
                <img alt='Page2' src='${Page5png8}'/>
                <img alt='page2' src='${Page5png9}'/>
                <img alt='page2' src='${Page5png10}'/>
                <img alt='page2' src='${Page5png11}'/>
                <img alt='page2' src='${Page5png12}'/>
                <img alt='page2' src='${Page5png13}'/>
              </div>
            </div>
            <div class='divdos'>
              <div>
                <h3>En NovaServ</h3>
                <p>Ofrecemos servicios de mantenimiento y reparación de neveras y lavadoras, hornos, secadoras es decir:</p>
                <span>Toda la línea blanca.</span>
              </div>
              <img alt='page2' src='${pc ? Page5png2 : Page5x14}'/>
            </div>
          </div>
        `
    },
    {
      name: "city2",
      content: `
        <div class="city">
          <p>Asesoría, instalación, mantenimiento y reparación de electrodomésticos a domicilio en Bogotá y ciudades aledañas.</p> 

          <p>Electrodomésticos mayores:<br/>Neveras, Lavadoras, Calentadores, Aires acondicionados, Nevecones, Estufas y campanas extractoras en Bogotá.</p> 

          <p>Contamos con servicio técnico en marcas como Samsung, LG, Haceb, Whirlpool, Centrales, Electrolux, Mabe, General Electric, entre otras.</p>

          <p>NOVASERV tiene personal  altamente capacitado para brindar un servicio de calidad, 100% garantizado y con repuestos originales para mantenimiento y reparación de electrodomésticos.</p>

          <p>Realizamos mantenimiento preventivo y soporte correctivo de las principales marcas de electrodomésticos usadas en Colombia con personal técnico idóneo para atender profesionalmente sugerencias y servicio técnico especializado para atender las necesidades de nuestros clientes.</p>

          <img alt='page1' id='whatsapp' src='${Page1boton}'/>

        </div>
      `
    }
  ]

  const about = [
    {
      name: "about",
      content: `
        <div class="city">
          <h1>NOSOTROS</h1>
          <h2>Historia</h2>
          <p>En el año 2020, nació una idea que cambiaría la vida de muchas familias. Jairo, Ingeniero apasionado y dedicado, se dio cuenta de que los electrodomésticos no solo eran máquinas, sino una parte esencial de cada hogar. Cada nevera, lavadora y estufa tenía su propia historia y una misión: hacer la vida de las personas más fácil y cómoda.</p>

          <p>Un día, después de ver la frustración de una madre que no podía preparar la cena porque su estufa se había averiado, Jairo decidió que quería marcar la diferencia. No solo quería reparar electrodomésticos, quería devolver la tranquilidad y el confort a cada hogar al que llegara. Así nació NOVASERV, con un propósito claro y un corazón lleno de determinación.</p>

          <p>Jairo sabía que no sería fácil. Con humildad y mucho esfuerzo, comenzó a construir su sueño. Recorrió barrios y ciudades, escuchando a las personas y entendiendo sus necesidades. Cada reparación no era solo un trabajo, era una promesa de calidad, confianza y compromiso.</p>

          <p>La reputación de NOVASERV comenzó a crecer gracias a la dedicación y el cuidado que Jairo y su equipo ponían en cada tarea. La satisfacción de ver a una familia sonreír cuando su electrodoméstico volvía a funcionar era el motor que impulsaba su labor diaria.</p>

          <p>Con el tiempo, NOVASERV se convirtió en mucho más que un simple servicio técnico. Se transformó en un símbolo de confianza y excelencia. Jairo, junto a un equipo de expertos apasionados, hizo de cada intervención una obra maestra, asegurándose de que cada hogar pudiera disfrutar de la comodidad y la tranquilidad que sus electrodomésticos proporcionaban.</p>

          <p>Hoy, NOVASERV sigue fiel a sus raíces, recordando siempre el humilde comienzo y la visión de Jairo de mejorar la vida de las personas a través de un servicio técnico de alta calidad. Cada llamada, cada reparación, es un tributo a la pasión y dedicación con la que todo comenzó.</p>

          <p>Gracias por confiar en nosotros. En NOVASERV, no solo reparamos electrodomésticos, cuidamos de tu hogar y de tus seres queridos.</p>

          <div class="mision">
            <div>
              <h2>Misión</h2>
              <p>
                Nuestra misión es proporcionar servicios técnicos de alta calidad en mantenimiento y reparación de electrodomésticos como lavadoras, estufas, calentadores de agua y neveras. Nos comprometemos a ofrecer soluciones rápidas, eficaces y confiables para garantizar la satisfacción y tranquilidad de nuestros clientes, extendiendo la vida útil de sus equipos y mejorando su experiencia en el hogar.
              </p>
            </div>
            <div>
              <h2>Visión</h2>
              <p>Nuestra visión es convertirnos en la empresa reconocida no solo por nuestra excelencia técnica en el sector de mantenimiento y reparación de electrodomésticos, sino también por nuestro compromiso con la sostenibilidad y el desarrollo comunitario. Aspiramos a ser la opción preferida de los hogares, ofreciendo soluciones integrales y personalizadas que contribuyan a un uso más eficiente y prolongado de sus electrodomésticos de uso diario.</p>
              <p>Queremos establecer un estándar de calidad y confianza en cada servicio que brindamos, asegurando que cada intervención no solo resuelva problemas inmediatos, sino que también prevenga futuros inconvenientes.</p>
              <p>Además, nos comprometemos a crear un ambiente de trabajo donde nuestros empleados puedan desarrollarse tanto profesional como personalmente, fomentando la capacitación continua y la innovación. Nuestro objetivo es no solo mejorar la vida útil de los electrodomésticos, sino también mejorar la calidad de vida de nuestros clientes y empleados.</p>
            </div>
          </div>

        </div>
      `
    },
    {
      name: "city2",
      content: `
        <div class="city">
          <p>Asesoría, instalación, mantenimiento y reparación de electrodomésticos a domicilio en Bogotá y ciudades aledañas.</p> 

          <p>Electrodomésticos mayores:<br/>Neveras, Lavadoras, Calentadores, Aires acondicionados, Nevecones, Estufas y campanas extractoras en Bogotá.</p> 

          <p>Contamos con servicio técnico en marcas como Samsung, LG, Haceb, Whirlpool, Centrales, Electrolux, Mabe, General Electric, entre otras.</p>

          <p>NOVASERV tiene personal  altamente capacitado para brindar un servicio de calidad, 100% garantizado y con repuestos originales para mantenimiento y reparación de electrodomésticos.</p>

          <p>Realizamos mantenimiento preventivo y soporte correctivo de las principales marcas de electrodomésticos usadas en Colombia con personal técnico idóneo para atender profesionalmente sugerencias y servicio técnico especializado para atender las necesidades de nuestros clientes.</p>

          <img alt='page1' id='whatsapp' src='${Page1boton}'/>

        </div>
      `
    }
  ]

  const selectPage = (page) => {
    switch(page){
      case 'home':
        setActualPage(dataPage)
        setIsHome(true)
        break;
      case 'city':
        setActualPage(city)
        setIsHome(false)
        break;
      case 'appliances':
        setActualPage(appliances)
        setIsHome(false)
        break;
      case 'brands':
        setActualPage(brands)
        setIsHome(false)
        break;
      case 'about':
        setActualPage(about)
        setIsHome(false)
        break;
    }
  }
  const [actualPage, setActualPage] = useState(dataPage)
  const [isHome, setIsHome] = useState(true)
  return (
    <div className="App">
      <Navbar page={selectPage}/>
      {actualPage.map((page, index) => {return(
        <Content key={index} name={page.name} content={page.content}/>
      )})}
      {!isHome && <>
        <Faq />
        <Footer />
      </>}
    </div>
  );
}

export default App;
